var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"form",staticClass:"min-h-screen pt-3 bg-gray-100",attrs:{"tag":"section"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passes = ref.passes;
return [_c('div',{staticClass:"w-full px-4 pt-1 pb-4 introduction-container"},[_c('h2',{staticClass:"mb-1 font-bold"},[_vm._v(" 业主认证 ")]),_c('p',[_vm._v(" 您当前正在进行业主认证，请您入您的真实信息（需与办理门禁系统时一致）进行认证。您的信息将由业委会进行人工审核，审核将在5个工作日内完成，请您耐心等待。 ")]),_c('p',{staticClass:"mt-3"},[_vm._v(" 当前状态："),_c('van-tag',{staticClass:"px-3 py-1",attrs:{"type":_vm.colors[_vm.status] ? _vm.colors[_vm.status] : 'default'}},[_vm._v(" "+_vm._s(_vm.status)+" ")])],1),(_vm.status == '未通过' && _vm.authenticationRemark)?_c('p',{staticClass:"py-3 pl-3 mt-3 text-sm text-white bg-red-500 rounded"},[_vm._v(" "+_vm._s(_vm.authenticationRemark)+" ")]):_vm._e()]),_c('van-cell-group',{attrs:{"inset":""}},[_c('ValidationProvider',{attrs:{"vid":"name","name":"姓名","mode":"lazy","rules":"required|min:2|max:20","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('van-field',{attrs:{"disabled":!['未认证', '未通过'].includes(_vm.status),"clearable":"","required":"","placeholder":"请输入您的姓名","label":"姓名","error":errors.length > 0,"error-message":errors[0]},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"vid":"unit","name":"楼号及单元","mode":"lazy","rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('van-field',{attrs:{"disabled":!['未认证', '未通过'].includes(_vm.status),"readonly":"","clearable":"","required":"","label":"楼号及单元","placeholder":"请选择楼号及单元","error":errors.length > 0,"error-message":errors[0]},on:{"click":function($event){_vm.showUnitPicker = true}},model:{value:(_vm.form.unit),callback:function ($$v) {_vm.$set(_vm.form, "unit", $$v)},expression:"form.unit"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"vid":"room","name":"房号","mode":"lazy","rules":"required|min:2|max:50","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('van-field',{attrs:{"disabled":!['未认证', '未通过'].includes(_vm.status),"clearable":"","placeholder":"请输入您的房号，例如：901","autocomplete":"off","label":"房号","required":"","error":errors.length > 0,"error-message":errors[0]},model:{value:(_vm.form.room),callback:function ($$v) {_vm.$set(_vm.form, "room", $$v)},expression:"form.room"}})]}}],null,true)}),_c('ValidationProvider',{ref:"mobile",attrs:{"vid":"mobile","name":"手机号码","mode":"lazy","rules":"required|phone","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('van-field',{attrs:{"disabled":!['未认证', '未通过'].includes(_vm.status),"required":"","clearable":"","placeholder":"请输入办理门禁时预留手机号码","autocomplete":"off","type":"tel","label":"手机号码","error":errors.length > 0,"error-message":errors[0]},model:{value:(_vm.form.mobile),callback:function ($$v) {_vm.$set(_vm.form, "mobile", $$v)},expression:"form.mobile"}})]}}],null,true)}),(['未认证', '未通过'].includes(_vm.status))?_c('ValidationProvider',{ref:"code",attrs:{"vid":"code","name":"验证码","mode":"lazy","rules":"required|length:6","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('van-field',{attrs:{"required":"","clearable":"","placeholder":"验证码","autocomplete":"off","type":"digit","label":"验证码","error":errors.length > 0,"error-message":errors[0]},scopedSlots:_vm._u([{key:"button",fn:function(){return [_c('van-button',{attrs:{"disabled":_vm.countdown > 0 || _vm.sendingCode || _vm.processing,"size":"small","type":"warning"},on:{"click":_vm.sendCode}},[_vm._v(" "+_vm._s(_vm.countdownText)+" ")])]},proxy:true}],null,true),model:{value:(_vm.form.code),callback:function ($$v) {_vm.$set(_vm.form, "code", $$v)},expression:"form.code"}})]}}],null,true)}):_vm._e()],1),_c('div',{staticClass:"w-full px-4 mt-6"},[_c('van-button',{attrs:{"type":"danger","block":"","round":"","loading":_vm.processing,"loading-text":"发送中...","disabled":!['未认证', '未通过'].includes(_vm.status)},on:{"click":function($event){return passes(_vm.onSubmit)}}},[_vm._v(" 立即认证 ")]),_c('p',{staticClass:"mt-3 text-xs text-center text-gray-500"},[_vm._v(" 点击「立即认证」即表示您已阅读并同意"),_c('router-link',{staticClass:"font-semibold text-gray-500",attrs:{"to":{ name: 'public.pages', params: { page: 'h5-agreement' } }}},[_vm._v(" 《用户许可协议》 ")])],1)],1),_c('van-popup',{attrs:{"round":"","position":"bottom","safe-area-inset-bottom":true},model:{value:(_vm.showUnitPicker),callback:function ($$v) {_vm.showUnitPicker=$$v},expression:"showUnitPicker"}},[_c('van-picker',{attrs:{"show-toolbar":"","columns":_vm.units},on:{"cancel":function($event){_vm.showUnitPicker = false},"confirm":_vm.onUnitConfirm}})],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }