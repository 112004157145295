<template>
  <ValidationObserver
    ref="form"
    v-slot="{ passes }"
    tag="section"
    class="min-h-screen pt-3 bg-gray-100"
  >
    <div class="w-full px-4 pt-1 pb-4 introduction-container">
      <h2 class="mb-1 font-bold">
        业主认证
      </h2>
      <p>
        您当前正在进行业主认证，请您入您的真实信息（需与办理门禁系统时一致）进行认证。您的信息将由业委会进行人工审核，审核将在5个工作日内完成，请您耐心等待。
      </p>
      <p class="mt-3">
        当前状态：<van-tag
          :type="colors[status] ? colors[status] : 'default'"
          class="px-3 py-1"
        >
          {{ status }}
        </van-tag>
      </p>
      <p
        v-if="status == '未通过' && authenticationRemark"
        class="py-3 pl-3 mt-3 text-sm text-white bg-red-500 rounded"
      >
        {{ authenticationRemark }}
      </p>
    </div>
    <van-cell-group inset>
      <ValidationProvider
        v-slot="{ errors }"
        vid="name"
        name="姓名"
        mode="lazy"
        rules="required|min:2|max:20"
        slim
      >
        <van-field
          v-model="form.name"
          :disabled="!['未认证', '未通过'].includes(status)"
          clearable
          required
          placeholder="请输入您的姓名"
          label="姓名"
          :error="errors.length > 0"
          :error-message="errors[0]"
        />
      </ValidationProvider>

      <ValidationProvider
        v-slot="{ errors }"
        vid="unit"
        name="楼号及单元"
        mode="lazy"
        rules="required"
        slim
      >
        <van-field
          v-model="form.unit"
          :disabled="!['未认证', '未通过'].includes(status)"
          readonly
          clearable
          required
          label="楼号及单元"
          placeholder="请选择楼号及单元"
          :error="errors.length > 0"
          :error-message="errors[0]"
          @click="showUnitPicker = true"
        />
      </ValidationProvider>
      <ValidationProvider
        v-slot="{ errors }"
        vid="room"
        name="房号"
        mode="lazy"
        rules="required|min:2|max:50"
        slim
      >
        <van-field
          v-model="form.room"
          :disabled="!['未认证', '未通过'].includes(status)"
          clearable
          placeholder="请输入您的房号，例如：901"
          autocomplete="off"
          label="房号"
          required
          :error="errors.length > 0"
          :error-message="errors[0]"
        />
      </ValidationProvider>

      <ValidationProvider
        v-slot="{ errors }"
        ref="mobile"
        vid="mobile"
        name="手机号码"
        mode="lazy"
        rules="required|phone"
        slim
      >
        <van-field
          v-model="form.mobile"
          :disabled="!['未认证', '未通过'].includes(status)"
          required
          clearable
          placeholder="请输入办理门禁时预留手机号码"
          autocomplete="off"
          type="tel"
          label="手机号码"
          :error="errors.length > 0"
          :error-message="errors[0]"
        />
      </ValidationProvider>

      <ValidationProvider
        v-if="['未认证', '未通过'].includes(status)"
        v-slot="{ errors }"
        ref="code"
        vid="code"
        name="验证码"
        mode="lazy"
        rules="required|length:6"
        slim
      >
        <van-field
          v-model="form.code"
          required
          clearable
          placeholder="验证码"
          autocomplete="off"
          type="digit"
          label="验证码"
          :error="errors.length > 0"
          :error-message="errors[0]"
        >
          <template
            #button
          >
            <van-button
              :disabled="countdown > 0 || sendingCode || processing"
              size="small"
              type="warning"
              @click="sendCode"
            >
              {{ countdownText }}
            </van-button>
          </template>
        </van-field>
      </ValidationProvider>
    </van-cell-group>

    <div class="w-full px-4 mt-6">
      <van-button
        type="danger"
        block
        round
        :loading="processing"
        loading-text="发送中..."
        :disabled="!['未认证', '未通过'].includes(status)"
        @click="passes(onSubmit)"
      >
        立即认证
      </van-button>
      <p class="mt-3 text-xs text-center text-gray-500">
        点击「立即认证」即表示您已阅读并同意<router-link
          :to="{ name: 'public.pages', params: { page: 'h5-agreement' } }"
          class="font-semibold text-gray-500"
        >
          《用户许可协议》
        </router-link>
      </p>
    </div>
    <van-popup
      v-model="showUnitPicker"
      round
      position="bottom"
      :safe-area-inset-bottom="true"
    >
      <van-picker
        show-toolbar
        :columns="units"
        @cancel="showUnitPicker = false"
        @confirm="onUnitConfirm"
      />
    </van-popup>
  </ValidationObserver>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import share from '@/mixins/share'
export default {
  name: 'PublicBinding',
  mixins: [share],
  data() {
    return {
      countdown: 0,
      sendingCode: false,
      intval: null,
      loading: true,
      processing: false,
      showUnitPicker: false,
      form: {
        name: null,
        mobile: null,
        unit: null,
        room: null
      },
      colors: {
        未认证: 'default',
        待审核: 'warning',
        未通过: 'danger',
        已认证: 'success',
      }
    }
  },
  computed: {
    ...mapState('Common/Cloud', ['tcb']),
    ...mapGetters('Common/Credential', ['userInfo']),
    ...mapState('Common/Config', ['units']),
    status() {
      return this.userInfo?.status ?? '未认证'
    },
    authenticationRemark() {
      return this.userInfo?.authenticationRemark ?? null
    },
    countdownText() {
      if (this.sendingCode) {
        return '发送验证码中...'
      }
      return this.countdown === 0
        ? '发送验证码'
        : `${this.countdown}秒`
    }
  },
  async mounted() {
    await this.$store.dispatch('Common/Credential/fetch')
    this.disableShareMenu()
    this.countdown = 0
    this.sendingCode = false
    this.intval = null
    this.form.name = this.userInfo?.name ?? null
    this.form.unit = this.userInfo?.unit ?? null
    this.form.room = this.userInfo?.room ?? null
    this.form.mobile = this.userInfo?.mobile ?? null
  },
  methods: {
    onUnitConfirm(values) {
      this.form.unit = values[0] == '无' ? null : values.join('-')
      this.showUnitPicker = false
    },
    async sendCode() {
      let result = await this.$refs.mobile.validate()
      // eslint-disable-next-line no-console
      console.log('sendCode', result)
      if (!result.valid) {
        this.$toast.fail('请您先输入正确的11位手机号码')
        return
      }
      this.sendingCode = true
      try {
        let { result } = await this.tcb.callFunction({
          name: 'sms',
          data: {
            $url: 'sendAuthenticationCode',
            mobile: this.form.mobile
          }
        })
        // eslint-disable-next-line no-console
        console.log('sendCode result', result)
        if (result?.code === 200) {
          this.$toast.success('验证码已发送')
          this.countdown = 60
          this.intval = setInterval(() => {
            this.countdown--
            if (this.countdown === 0 && this.intval) {
              clearInterval(this.intval)
            }
          }, 1000)
        } else {
          this.$toast.fail('发送验证码失败')
          // eslint-disable-next-line no-console
          console.error(result)
        }
      } catch (err) {
        this.$toast.fail('无法发送验证码')
        // eslint-disable-next-line no-console
        console.error(err)
      } finally {
        this.sendingCode = false
      }
    },
    async onSubmit() {
      this.processing = true

      const data = {
        $url: 'authentication',
        form: this.form
      }
      // eslint-disable-next-line no-console
      console.warn('onSubmit', JSON.stringify(data))

      try {
        let { result } = await this.tcb.callFunction({
          name: 'subscribers',
          data
        })
        // eslint-disable-next-line no-console
        console.log('subscribers', result)
        if(result.code == 422) {
          let { data } = result
          if(data?.code) {
            this.$refs.code.applyResult({
              errors: data.code, // array of string errors
              valid: false, // boolean state
              failedRules: {} // should be empty since this is a manual error.
            })
          }
          if(data?.mobile) {
            this.$refs.mobile.applyResult({
              errors: data.mobile, // array of string errors
              valid: false, // boolean state
              failedRules: {} // should be empty since this is a manual error.
            })
          }
        }
        else if( result.code == 200) {
        this.$dialog
          .confirm({
            title: '认证内容已发送',
            message: '您的认证内容已发送，请您耐心等待人工认证，预计认证时间：5个工作日。',
            theme: 'round-button',
            showCancelButton: true,
            cancelButtonText: '留在此页',
            confirmButtonText: '关闭窗口'
          })
          .then(() => {
            try {
              wx.closeWindow()
            } catch (err) {
              // eslint-disable-next-line no-console
              console.error(err)
            }
          }).catch(() => {
            this.fetch()
          })
        } else {
          this.$toast.fail( result.error)
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error('onSubmit', err)
        this.$notify({
          type: 'danger',
          message: '发送失败，由于微信服务异常，暂时无法发送'
        })
      } finally {
        this.processing = false
      }
    }
  }
}
</script>

<style></style>
